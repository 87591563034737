import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Творчі Петлі
			</title>
			<meta name={"description"} content={"Розкривайте таємниці рукоділля"} />
			<meta property={"og:title"} content={"Творчі Петлі"} />
			<meta property={"og:description"} content={"Розкривайте таємниці рукоділля"} />
			<meta property={"og:image"} content={"https://nulvorich.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://nulvorich.com/img/4634647.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://nulvorich.com/img/4634647.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://nulvorich.com/img/4634647.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://nulvorich.com/img/4634647.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://nulvorich.com/img/4634647.png"} />
			<meta name={"msapplication-TileImage"} content={"https://nulvorich.com/img/4634647.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="70px 0 180px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 100px 0px 100px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 100px 30px 100px"
					lg-padding="0px 0px 0px 0px"
					font="--headline1"
					color="--primary"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Будемо на зв'язку:
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--darkL1" text-align="center">
				Знайдіть відповіді на свої запитання, розпочніть свій творчий шлях або просто поговоріть про рукоділля:
				</Text>
				<Link
					href="mailto:contact@nulvorich.com"
					text-align="center"
					color="--darkL1"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					contact@nulvorich.com
				</Link>
				<Link
					href="tel:+38099 654 32 23"
					text-align="center"
					color="--darkL1"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					+38099 654 32 23
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--darkL1" text-align="center">
				вул. Богдана Хмельницького 4, м. Дніпро, 49051
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});